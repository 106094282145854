<template>
  <div class="container">
    <div class="content">
      <!-- tree-table表格组件 -->
      <tree-table class="treeTable" :is-fold="false" :data="orgList" :expand-type="false" :columns="columns" :selection-type="false" :show-row-hover="true" stripe>
        <template slot="opt" slot-scope="scope">
          <el-tooltip class="item" effect="dark" content="查看账户" placement="top">
            <el-button type="primary" icon="el-icon-search" circle size="mini" @click="open('query', scope.row)"></el-button>
          </el-tooltip>
        </template>
      </tree-table>
    </div>
    <!-- 账户表格 -->
    <el-dialog title="账户" :visible.sync="dialog1" width="50%" @open="initAccount" class="tableDialog" :close-on-click-modal="false" v-dialogDrag @close="accountList=[]">
      <div class="container noangle">
        <div class="header">
          <el-button type="success" size="mini" icon="el-icon-plus" @click="open('add')">新增账户</el-button>
        </div>
        <div class="content">
          <el-table :data="accountList" height="350px">
            <el-table-column property="username" label="用户名" show-overflow-tooltip></el-table-column>
            <el-table-column prop="realname" label="姓名" show-overflow-tooltip></el-table-column>
            <el-table-column property="loginStatus" label="登陆状态" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag type="danger" size="normal" v-show="scope.row.loginStatus===0">未登录</el-tag>
                <el-tag type="success" size="normal" v-show="scope.row.loginStatus===1">已登录</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="roleName" label="角色" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" content="修改" placement="top">
                  <el-button type="warning" @click="open('edit',scope.row)" size="mini" icon="el-icon-edit" circle></el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="删除" placement="top">
                  <el-button type="danger" @click="deleteAccount(scope.row)" size="mini" icon="el-icon-delete-solid" circle></el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="重置密码" placement="top">
                  <el-button type="primary" @click="resetPassword(scope.row)" size="mini" icon="el-icon-refresh" circle></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="footer">
          <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="5" layout="total, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </div>
    </el-dialog>
    <!-- 组织机构弹窗 -->
    <el-dialog :title="optFlag?`添加账户`:`修改账户`" :visible.sync="dialog2" width="30%" @close="clear" v-dialogDrag :close-on-click-modal="false">
      <el-form :model="accountForm" ref="accountForm" :rules="rules">
        <el-form-item label="姓名" prop="realname" :label-width="formLabelWidth">
          <el-input v-model="accountForm.realname" autocomplete="off" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="用户名" prop="username" :label-width="formLabelWidth">
          <el-input v-model="accountForm.username" autocomplete="off" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item label="密码" :prop="optFlag?'password':''" :label-width="formLabelWidth">
          <el-input v-model="accountForm.password" autocomplete="off" placeholder="请输入密码" show-password></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="roleId" :label-width="formLabelWidth">
          <el-select v-model="accountForm.roleId" placeholder="请选择角色">
            <el-option v-for="(item,i) in roleList" :key="i" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="权限管理" :label-width="formLabelWidth">
          <el-button type="primary" @click="open('authority')" icon="el-icon-search" size="small">查看权限</el-button>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="dialog2 = false">取 消</el-button>
        <el-button type="primary" @click="addAccount" v-if="optFlag">添 加</el-button>
        <el-button type="primary" @click="updateAccount" v-if="!optFlag">修 改</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import TreeTable from 'vue-table-with-tree-grid'
export default {
  components: {
    TreeTable
  },
  data () {
    return {
      typeName: '',
      parentId: null,
      formLabelWidth: '100px',
      dialog1: false, // 账户弹窗开关
      dialog2: false, // 账户表单开关
      orgList: [], // 组织机构列表
      ogranizedId: 0,
      roleList: [], // 角色列表
      optFlag: true,
      accountForm: {
        realname: '',
        username: '',
        password: '',
        roleId: ''
      },
      accountId: 0,
      accountList: [],
      accountFormCopy: {},
      columns: [
        {
          label: '机构名称',
          prop: 'label'
        },
        {
          label: '行政编号',
          prop: 'areaCode'
        },
        {
          label: '操作',
          type: 'template',
          template: 'opt'
        }
      ],
      currentPage: 1,
      total: 0,
      rules: {
        realname: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { pattern: /(^$)|(^[\u4E00-\u9FA5a-zA-Z0-9]{1,10}$)/, message: '请输入合法的姓名', trigger: 'blur' }
        ],
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        roleId: [
          { required: true, message: '请选择角色', trigger: 'change' }
        ]
      }
    }
  },
  watch: {
    '$route.path': 'changeRoute'
  },
  mounted () {
    this.accountFormCopy = JSON.stringify(this.accountForm)
    this.changeRoute()
  },
  created () {
    switch (parseInt(this.$route.params.id)) {
      case 1:
        this.typeName = '消防部门'
        this.parentId = parseInt(this.$route.params.id)
        break
      case 2:
        this.typeName = '运维公司'
        this.parentId = parseInt(this.$route.params.id)
        break
      case 3:
        this.typeName = '安装公司'
        this.parentId = parseInt(this.$route.params.id)
        break
    }
    this.initTable()
    this.getRole()
  },
  methods: {
    open (flag, row) {
      switch (flag) {
        case 'query':
          this.dialog1 = true
          this.ogranizedId = row.value
          break
        case 'add':
          this.dialog2 = true
          this.optFlag = true
          break
        case 'edit':
          this.dialog2 = true
          this.$nextTick(() => {
            this.$refs.accountForm.clearValidate()
          })
          this.optFlag = false
          this.accountForm = JSON.parse(JSON.stringify(row))
          this.accountId = row.id
          break
      }
    },
    // 查找账户
    async initTable () {
      const { data: result } = await this.$axios.get('organization/listVOWeb', { params: { parentId: this.parentId } })
      if (result.code === 200) {
        this.orgList = result.data
        this.total = result.total
      } else {
        this.$message.error(result.msg)
      }
    },
    // 删除组织机构
    async deleteAccount (row) {
      const confirm = await this.$confirm('此操作将删除该账户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.delete(`/user/del/${row.id}`)
        if (result.code === 200) {
          this.$message.success('删除成功')
          this.initAccount()
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    // 添加账户
    async addAccount () {
      this.$refs.accountForm.validate(async valid => {
        if (valid) {
          this.accountForm.ogranizedId = this.ogranizedId
          const { data: result } = await this.$axios.post('/user/add', this.accountForm)
          if (result.code === 200) {
            this.$message.success('添加成功')
            this.dialog2 = false
            this.initAccount()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 修改账户
    async updateAccount () {
      this.$refs.accountForm.validate(async valid => {
        if (valid) {
          this.accountForm.ogranizedId = this.ogranizedId
          const { data: result } = await this.$axios.put(`/user/edit/${this.accountId}`, this.accountForm)
          if (result.code === 200) {
            this.$message.success('修改成功')
            this.dialog2 = false
            this.initAccount()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    async resetPassword (user) {
      const confirm = await this.$confirm('此操作将重置该账户密码, 默认密码:123456?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      console.log(user)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.post(`/user/resetPass/${user.username}`)
        if (result.code === 200) {
          this.$message.success('重置成功,默认密码:123456')
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    clear () {
      this.accountForm = JSON.parse(this.accountFormCopy)
      this.$refs.accountForm.resetFields()
    },
    // 改变路由后变更类型
    changeRoute () {
      switch (parseInt(this.$route.params.id)) {
        case 1:
          this.typeName = '消防部门'
          this.parentId = parseInt(this.$route.params.id)
          break
        case 2:
          this.typeName = '运维公司'
          this.parentId = parseInt(this.$route.params.id)
          break
        case 3:
          this.typeName = '安装公司'
          this.parentId = parseInt(this.$route.params.id)
          break
      }
      this.initTable()
    },
    // 初始化账户
    async initAccount () {
      const data = {
        pageNo: this.currentPage,
        pageSize: 5,
        ogranizedId: this.ogranizedId
      }
      const { data: result } = await this.$axios.get('/user/list', { params: data })
      if (result.code === 200) {
        this.accountList = result.data.result
        this.total = result.data.total
      } else {
        this.$message.error(result.msg)
      }
    },
    // 翻页
    handleCurrentChange (val) {
      this.currentPage = val
      this.initAccount()
    },
    // 获取角色列表
    async getRole () {
      const { data: result } = await this.$axios.get('/role/list')
      if (result.code === 200) {
        this.roleList = result.data
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 10px !important;
  box-sizing: border-box;
  ::v-deep .tableDialog {
    ::v-deep .el-dialog__body {
      padding: 0;
      .container {
        margin-top: 0;
        .content {
          overflow: hidden;
        }
        .footer {
          padding: 5px;
        }
      }
    }
  }
  .content {
    overflow-y: auto !important;
  }
}
::v-deep .el-table__expanded-cell {
  padding: 0;
}
</style>
